import React from 'react'
import { usePlausible } from 'next-plausible'
import { useTranslation } from 'next-i18next'

import {
  Localisation,
  urlAuthConfig,
  getHostForEnvironment,
} from '@web-apps/utils-shared'
import { Footer } from '@web-apps/feature-creator-page'

import { SEOMetaHead } from './SEOMetaHead'

import {
  StyledContainer,
  StyledContent,
  StyledContentWrapper,
} from './PageWrapper.styles'
import { PageWrapperProps } from './PageWrapper.types'

export const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  language = Localisation.DEFAULT_LANGUAGE,
  seo,
  footerSettings,
  fullWidth,
  ...divProps
}) => {
  const { t } = useTranslation(['common'])
  const plausible = usePlausible()
  const sendAnalytics = (id: string, variant: string) =>
    plausible(id, { props: { variant } })

  return (
    <StyledContainer>
      <SEOMetaHead {...seo} />

      <StyledContentWrapper id="scrollable-wrapper">
        <StyledContent
          id="page-content-wrapper"
          $fullWidth={fullWidth}
          $hasFooter={!!footerSettings}
          {...divProps}
        >
          {children}
        </StyledContent>
        {footerSettings && (
          <Footer
            showBanner={footerSettings.show_sticky_gradient_footer}
            showClassic={footerSettings.show_classic_footer}
            title={t('common:footer_title')}
            url={
              urlAuthConfig[getHostForEnvironment(process.env.NODE_ENV)]
                .register
            }
            onBannerClose={() =>
              sendAnalytics('banner:close', 'footer_sticky_gradient')
            }
            onBannerClick={() =>
              sendAnalytics('banner:click', 'footer_sticky_gradient')
            }
            onFooterClick={() =>
              sendAnalytics('banner:click', 'footer_classic')
            }
          />
        )}
      </StyledContentWrapper>
    </StyledContainer>
  )
}
