import { AxiosError } from 'axios'

import { ErrorCodes } from '@web-apps/utils-types'
import { isFetchBaseQueryErrorType } from '@web-apps/utils-shared'
import { GetServerSidePropsContext } from 'next'

type HandleErrorFromResponseProps = {
  erroredResponse?: AxiosError
}

type ErrorHandlerFunction = (args?: HandleErrorFromResponseProps) => {
  props: {
    error: ErrorCodes
  }
}

const redirectTo404: ErrorHandlerFunction = () => {
  return { props: { error: ErrorCodes.NOT_FOUND } }
}

const unexpectedError: ErrorHandlerFunction = () => {
  return { props: { error: ErrorCodes.GENERIC } }
}

// extend with specific error handlers for each case
const ERROR_HANDLERS_FOR_STATUS: { [key: number]: ErrorHandlerFunction } = {
  404: redirectTo404,
}

const extractErrorFromHttpStatus = (httpStatus: number) => {
  if (httpStatus >= 400 && httpStatus <= 599) {
    const errorHandler =
      ERROR_HANDLERS_FOR_STATUS[httpStatus] || unexpectedError

    return errorHandler()
  }

  return { props: { error: ErrorCodes.GENERIC } }
}
export const handleErroredResponse: ErrorHandlerFunction = (args) => {
  const erroredResponse = args?.erroredResponse
  if (!erroredResponse?.response)
    return unexpectedError({
      erroredResponse,
    })

  const httpStatus = erroredResponse?.response?.status

  return extractErrorFromHttpStatus(httpStatus)
}

export const handleReduxToolkitQueryError = (error: unknown): ErrorCodes => {
  if (isFetchBaseQueryErrorType(error)) {
    const errorInfo = extractErrorFromHttpStatus(error.status as number)

    return errorInfo.props.error
  }

  return ErrorCodes.GENERIC
}

export const getOriginFromContext = (context: GetServerSidePropsContext) => {
  let protocol = 'https:'
  if (!context.req.headers.host || context.req.headers.host.includes('local'))
    protocol = 'http:'

  return `${protocol}//${context.req.headers.host}`
}
