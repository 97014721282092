import styled from 'styled-components'

import { MAX_PAGE_WIDTH_IN_PX } from '../../utils/constants/theme.constants'

export const StyledContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  min-height: 100%;
`

export const StyledContentWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  z-index: 2;
`

export const StyledContent = styled.div<{
  $fullWidth?: boolean
  $hasFooter?: boolean
}>`
  flex: 1;
  width: 100%;
  max-width: ${({ $fullWidth }) =>
    $fullWidth ? '100%' : `${MAX_PAGE_WIDTH_IN_PX}px`};
  margin: 0 auto;
  padding-inline: ${({ $fullWidth }) => ($fullWidth ? 0 : '24px')};
  ${({ $hasFooter }) => $hasFooter && 'padding-bottom: 64px'};
`
