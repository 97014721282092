import React, { useEffect, useMemo, useRef, useState } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'react-i18next'
import { usePlausible } from 'next-plausible'
import { convert } from 'html-to-text'
import { connect } from 'react-redux'
import cloneDeep from 'lodash.clonedeep'
import getConfig from 'next/config'

import { wrapper, AppStore } from '../../../src/services/store'
import {
  isLinkUnsafe,
  isCustomLinkSection,
  isLinkableSection,
  sectionLinkAdapterFacade,
  CreatorThemeWrapper,
  useGetCreatorQuery,
  CreatorAPIEndpointsType,
  getAvatarImage,
  decodeSectionLinks,
  decodeLink,
} from '@web-apps/feature-creator-page'
import {
  ExternalLinkMode,
  LinkableSectionType,
  SectionTypeEnum,
  SectionType,
  CreatorPageType,
} from '@web-apps/utils-types'
import { ProceedToLinkPopUp } from '@web-apps/ui-shared'
import {
  api,
  creatorUrlBuilders,
  imageUrlBuilder,
  initAPI,
  Localisation,
  useInstagramIosBrowserExtraHeight,
} from '@web-apps/utils-shared'

import { PageWrapper } from '../../../src/layouts'
import { CreatorPageContainer } from '../../../src/containers/CreatorPage'

import { Error } from '../../../src/sections/Error'
import {
  fromSectionToAnalyticProps,
  plausiblePageView,
  PUBLIC_CREATOR_PROFILE_SHARE_CLICK,
  SECTION_CLICK_EVENT,
  SECTION_REDIRECT_EVENT,
  SOCIAL_CLICK_EVENT,
} from '../../../src/utils/helpers/analytics.helpers'
import { handleReduxToolkitQueryError } from '../../../src/utils/helpers/app.helpers'
import {
  detectDeviceAndBrowser,
  DeviceDetectionResultType,
} from '../../../src/utils/helpers/deviceDetector.helpers'

const { i18n } = require('../../../next-i18next.config')

type CreatorPageProps = {
  deviceDetection: DeviceDetectionResultType
  creatorSlug: string
  absoluteCreatorURL: string
  language: string
}

const CreatorPage = ({
  deviceDetection,
  creatorSlug,
  absoluteCreatorURL,
  language,
}: CreatorPageProps) => {
  const { t } = useTranslation(['creator'])
  const plausible = usePlausible()
  const extraPadding = useInstagramIosBrowserExtraHeight()

  const isRedirectCheckDoneRef = useRef(false)
  const [restrictedUrl, setRestrictedUrl] = useState<string>()

  const { data: creatorPageResponse, error } = useGetCreatorQuery({
    slug: creatorSlug,
    encodeSectionLinksNeeded: true,
  })

  // put urls back in the creator page
  const creatorPage = creatorPageResponse && cloneDeep(creatorPageResponse)
  const sections = creatorPage?.sections || []
  sections.forEach((section: SectionType) => {
    decodeSectionLinks(section)

    if (section.type === SectionTypeEnum.CONTAINER) {
      const items = section.items || []
      items.forEach((s) => decodeSectionLinks(s as SectionType))
    }
  })

  const taglineText = useMemo(
    () => convert(creatorPage?.tagline || ''),
    [creatorPage?.tagline]
  )

  // Handle redirect
  useEffect(() => {
    const redirectSection = creatorPage?.redirect?.section as SectionType
    decodeSectionLinks(redirectSection)
    if (isRedirectCheckDoneRef.current || !redirectSection) return

    let redirectUrl: string | undefined

    if (redirectSection && isLinkableSection(redirectSection)) {
      if (isCustomLinkSection(redirectSection)) {
        const linkProps = sectionLinkAdapterFacade(
          redirectSection as LinkableSectionType,
          {
            useRelativePaths: true,
          }
        )
        redirectUrl = linkProps?.href
      } else if (
        redirectSection.type === SectionTypeEnum.EXTERNAL_LINK &&
        redirectSection.mode === ExternalLinkMode.EMBED &&
        redirectSection._links?.about?.href
      ) {
        redirectUrl = redirectSection._links.about.href
      }
    }

    if (redirectUrl && redirectSection) {
      const sectionProps = fromSectionToAnalyticProps(redirectSection)
      const props = { ...sectionProps, url: window.location.href }

      plausible(SECTION_CLICK_EVENT, { props })
      plausible(SECTION_REDIRECT_EVENT, { props })
    }

    if (redirectUrl && isLinkUnsafe(redirectUrl)) {
      setRestrictedUrl(redirectUrl)
    } else if (redirectUrl) {
      window.location.href = redirectUrl
    }

    isRedirectCheckDoneRef.current = true
  }, [creatorPage?.redirect, plausible])

  if (error) return <Error error={handleReduxToolkitQueryError(error)} />
  if (!creatorPage) return null
  if (creatorPage?.redirect?.section && !restrictedUrl) return null

  if (restrictedUrl) {
    return (
      <ProceedToLinkPopUp
        proceedUrl={restrictedUrl}
        translations={{
          title: t('creator:restricted.title'),
          description: t('creator:restricted.description'),
          proceed: t('creator:restricted.proceed'),
          cancel: t('creator:restricted.cancel'),
        }}
        isOpen={true}
        closeDialog={() => {
          window.location.href = 'https://zezam.io'
        }}
      />
    )
  }

  return (
    <CreatorThemeWrapper
      customizationProps={creatorPage.styles}
      extraPadding={extraPadding}
    >
      <PageWrapper
        fullWidth
        language={language}
        footerSettings={creatorPage.settings}
        seo={{
          title: creatorPage.name,
          description: taglineText,
          socialMedia: {
            title: creatorPage.name,
            description: taglineText,
            imageURL: imageUrlBuilder({
              image: getAvatarImage(creatorPage),
              width: 960,
            }),
            canonicalURL: absoluteCreatorURL,
          },
        }}
      >
        <CreatorPageContainer
          creatorPage={creatorPage}
          socialLinkTracker={({ href, type }) => {
            if (href) {
              plausible(SOCIAL_CLICK_EVENT, {
                props: {
                  url: href,
                  type: type,
                },
              })
            }
          }}
          sectionLinkTracker={(section) =>
            plausible(SECTION_CLICK_EVENT, {
              props: fromSectionToAnalyticProps(section),
            })
          }
          onShareClick={() => plausible(PUBLIC_CREATOR_PROFILE_SHARE_CLICK)}
          deviceDetection={deviceDetection}
          enableShareFeature
        />
        <div style={{ paddingBottom: extraPadding }}></div>
      </PageWrapper>
    </CreatorThemeWrapper>
  )
}

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async (context) => {
    // on server side the code to initAPI at _app is not executed
    // TODO: find a way to make it in one place only, maybe, using an High Order function for getServerSideProps
    const { publicRuntimeConfig } = getConfig()
    initAPI({
      baseURL: publicRuntimeConfig.baseUrls.backend,
    })

    const deviceDetection = detectDeviceAndBrowser(
      context.req.headers['user-agent'] as string,
      context.req.headers
    )

    const language = Localisation.DEFAULT_LANGUAGE
    const creatorSlug = String(context.query.creatorId)
    const localisation = await serverSideTranslations(
      language,
      ['navigation', 'creator', 'common', 'app'],
      {
        i18n,
      }
    )

    store.dispatch(
      (api.endpoints as CreatorAPIEndpointsType).getCreator.initiate({
        slug: creatorSlug,
        encodeSectionLinksNeeded: true,
      })
    )

    await Promise.all(api.util.getRunningOperationPromises())

    const queryResponse = Object.entries(store.getState().api.queries)[0][1]
      ?.data as CreatorPageType

    if (queryResponse?.redirect?.section) {
      const redirectSection = queryResponse.redirect.section

      if (redirectSection._links?.alternate?.href) {
        await plausiblePageView(context.req, redirectSection)
        return {
          redirect: {
            destination: decodeLink(redirectSection._links.alternate?.href),
            permanent: false,
          },
        }
      } else if (redirectSection._links?.about?.href) {
        await plausiblePageView(context.req, redirectSection)
        return {
          redirect: {
            destination: decodeLink(redirectSection._links.about.href),
            permanent: false,
          },
        }
      }
    }

    return {
      props: {
        deviceDetection,
        creatorSlug,
        absoluteCreatorURL: creatorUrlBuilders.creatorUrlBuilder({
          creatorSlug,
        }),
        language,
        ...localisation,
      },
    }
  }
)

export default connect((state: AppStore) => state)(CreatorPage)
