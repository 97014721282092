import Head from 'next/head'

import { SEOMetaDataType } from '../PageWrapper.types'

export const SEOMetaHead = ({
  title,
  description,
  socialMedia,
}: SEOMetaDataType) => {
  return (
    <Head>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {socialMedia?.title && (
        <>
          <meta property="og:title" content={socialMedia.title} />
          <meta property="twitter:title" content={socialMedia.title} />
        </>
      )}
      {socialMedia?.description && (
        <>
          <meta property="og:description" content={socialMedia.description} />
          <meta
            property="twitter:description"
            content={socialMedia.description}
          />
        </>
      )}
      {socialMedia?.imageURL && (
        <>
          <meta property="og:image" content={socialMedia.imageURL} />
          <meta property="twitter:image" content={socialMedia.imageURL} />
        </>
      )}
      {socialMedia?.title && (
        <meta property="og:url" content={socialMedia.canonicalURL} />
      )}
    </Head>
  )
}
