import React from 'react'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

import {
  CreatorPageContent,
  useCreateSignupMutation,
} from '@web-apps/feature-creator-page'
import {
  CreatorPageType,
  SocialLinkType,
  SectionType,
  CreatorPageMessagesType,
} from '@web-apps/utils-types'
import { DeviceDetectionResultType } from '../../utils/helpers/deviceDetector.helpers'

type ContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  creatorPage: CreatorPageType
  socialLinkTracker?: (socialLink: SocialLinkType) => void
  sectionLinkTracker?: (section: SectionType) => void
  onShareClick?: () => void
  renderContentWrapper?: (children: React.ReactNode) => JSX.Element
  enableShareFeature?: boolean
  deviceDetection?: DeviceDetectionResultType
  preventContactListSubmission?: boolean
}

export const CreatorPageContainer = ({
  deviceDetection,
  creatorPage,
  socialLinkTracker,
  sectionLinkTracker,
  onShareClick,
  renderContentWrapper,
  enableShareFeature = false,
  preventContactListSubmission = false,
  ...divProps
}: ContainerProps) => {
  const { t } = useTranslation(['app', 'creator'])

  const messages: CreatorPageMessagesType = {
    freeLabel: t('app:free_symbol'),
    contactList: {
      placeholder: t('creator:contact_list_section.placeholder'),
      successMessage: t('creator:contact_list_section.successMessage'),
      errorMessage: t('app:generic_error'),
      requiredMessage: t('app:field_is_required'),
      emailValidationMessage: t('app:email_validation_message'),
    },
  }

  return (
    <CreatorPageContent
      {...divProps}
      renderContentWrapper={renderContentWrapper}
      creatorPage={creatorPage}
      messages={messages}
      CustomLinkWrapper={({ children, href, isExternalHref }) =>
        href && !isExternalHref ? (
          <Link href={href} passHref>
            {children}
          </Link>
        ) : (
          <>{children}</>
        )
      }
      socialLinkTracker={socialLinkTracker}
      sectionLinkTracker={sectionLinkTracker}
      onShareClick={onShareClick}
      useRelativePaths
      translations={{
        restrictPopup: {
          title: t('creator:restricted.title'),
          description: t('creator:restricted.description'),
          proceed: t('creator:restricted.proceed'),
          cancel: t('creator:restricted.cancel'),
        },
      }}
      skipImageErrorHandler
      useCreateSignupMutation={useCreateSignupMutation}
      enableShareFeature={enableShareFeature}
      preventContactListSubmission={preventContactListSubmission}
    />
  )
}
